<template>
  <div class="player" v-if="!page404">
    <XNav
      v-if="player"
      :content="[
        { t: 'common.players' },
        { name: player.First_Name + ' ' + player.Last_Name },
      ]"
    />
    <div class="player-info">
      <picture v-if="player">
        <template v-if="player.picture && !player.PrivatePicture">
          <source
            :srcset="
              `${$bucketUrl}/images/players/${player._id}/${player.picture}.webp`
            "
            type="image/webp"
          />
          <source
            :srcset="
              `${$bucketUrl}/images/players/${player._id}/${player.picture}.png`
            "
            type="image/png"
          />
          <img
            :src="
              `${$bucketUrl}/images/players/${player._id}/${player.picture}.png`
            "
          />
        </template>
        <template v-else>
          <source srcset="@/assets/default-player.png" type="image/png" />
          <img src="@/assets/default-player.png" />
        </template>
      </picture>
      <div class="player-name" v-if="player">
        <div class="number">{{ player.JerseyNumber }}</div>
        <div class="name">
          <p class="firstname" ref="firstname">{{ player.First_Name }}</p>
        </div>
        <div class="name">
          <p class="lastname" ref="lastname">{{ player.Last_Name }}</p>
        </div>
        <p v-if="player.BirthDate">
          {{ $t("player.age") }} :
          {{
            Math.floor((Date.now() - new Date(player.BirthDate)) / 31557600000)
          }}
        </p>
        <p v-if="player.Nationality">
          {{ $t("player.nationality") }} :
          <Flag :flag-code="player.Nationality" class="flag" />
        </p>
        <div class="mini-awards" v-if="awards[0]">
          <div
            class="mini-award"
            v-for="award in awards"
            :key="award.Awards._id"
          >
            <img :src="`${$bucketUrl}/images/awards/${award.Awards._id}.png`" />
            <div class="text">
              <p>{{ award.Awards.name }}</p>
              <p class="detail">{{ award.League }}</p>
              <p class="detail">{{ award.Season }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PlayerStatsTable v-if="stats.length" :content="stats" />

    <button class="share-button" v-if="sharable" @click="share">
      <span class="icon">&#xe804;</span> {{ $t("common.shareThisPage") }}
    </button>

    <div class="box" v-if="awards[0]">
      <h2>{{ $t("common.awards") }}</h2>
      <ul v-if="awards" class="awards">
        <li v-for="award in awards" :key="award.Awards._id">
          <img
            class="award-image"
            :src="`${$bucketUrl}/images/awards/${award.Awards._id}.png`"
          />
          <div class="award-info">
            <h3>
              {{ award.Awards.name
              }}<template v-if="award.extra"> - {{ award.extra }}</template>
            </h3>
            <p class="season-info">{{ award.Season }} - {{ award.League }}</p>
            <p>{{ award.Awards.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <Page404 v-else />
</template>

<script>
import axios from "axios";
import fitty from "fitty";
import PlayerStatsTable from "@/components/PlayerStatsTable.vue";
import Flag from "@/components/Flag.vue";
import XNav from "@/components/XNav.vue";
import Page404 from "@/views/404.vue";
export default {
  components: {
    PlayerStatsTable,
    Flag,
    XNav,
    Page404,
  },
  data() {
    return {
      player: null,
      stats: [],
      awards: [],
      sharable: !!navigator.share,
      page404: false,
    };
  },
  methods: {
    share() {
      navigator.share({
        title: this.player.First_Name + " " + this.player.Last_Name,
        url: window.location.href,
      });
    },
  },
  mounted() {
    Promise.all([
      axios
        .get(`${this.$apiUrl}/players/${this.$route.params.id}`)
        .then((res) => {
          this.player = res.data[0];
          this.$nextTick(() => {
            fitty(this.$refs["lastname"], { maxSize: 70 });
            fitty(this.$refs["firstname"], { maxSize: 50 });
          });
        }),
      axios
        .get(`${this.$apiUrl}/players/${this.$route.params.id}/stats`)
        .then((res) => {
          this.stats = res.data;
        }),
      axios
        .get(`${this.$apiUrl}/players/${this.$route.params.id}/awards`)
        .then((res) => {
          this.awards = res.data;
        }),
    ])
      .then(() => this.$store.dispatch("pageLoad", false))
      .catch(() => {
        this.page404 = true;
        this.$store.dispatch("pageLoad", false);
      });
  },
};
</script>
<style lang="scss" scoped>
@import "@/theme.scss";
.player-info {
  display: flex;
  flex-direction: column-reverse;
  @extend %light-pattern;
  & > picture img {
    height: auto;
    width: 100%;
    margin-top: 15px;
    display: block;
  }
}
.player-name {
  @extend %font-bold;
  color: $secondary;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 200px;
  .name {
    z-index: 2;
  }
  p {
    white-space: nowrap;
  }
  .firstname {
    font-size: 50px;
  }
  .lastname {
    font-size: 70px;
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-top: -10px;
  }
  .flag {
    display: inline-block;
    height: 1.2em;
  }
}
.number {
  position: absolute;
  top: 10%;
  right: 10%;
  color: transparent;
  font-family: sans-serif;
  -webkit-text-stroke: darken($light, 5) 4px;
  font-size: 180px;
  line-height: 150px;
  user-select: none;
}
.share-button {
  margin: 15px 0;
  font-size: 20px;
  height: 50px;
  width: 100%;
  padding: 10px;
  background-color: $secondary;
  border: none;
  color: white;
}
@media only screen and (min-width: $mobile-breakpoint) {
  .player-info {
    flex-direction: row;
    & > picture img {
      //flex: 0 0 300px;
      height: auto;
      width: 300px;
      min-width: 300px;
    }
  }
  .player-name {
    flex: 1 0;
    max-width: calc(100% - 330px);
  }
  .number {
    font-size: 200px;
    line-height: 200px;
  }
}
.awards {
  list-style: none;
  li {
    display: flex;
    &:nth-child(odd) {
      background-color: #ffffff;
    }

    &:nth-child(even) {
      background-color: #eeeeee;
    }
  }
}
.award-image {
  height: 70px;
  margin: 10px;
}
.award-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  .season-info {
    color: #555;
    font-size: 0.9em;
  }
}
.mini-awards {
  display: flex;
  flex-direction: row;
}
.mini-award {
  position: relative;
  img {
    height: 45px;
    margin: 5px;
  }
  &:hover {
    .text {
      display: block;
    }
  }
  .text {
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-30px, 90%);
    white-space: nowrap;
    background: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    text-align: center;
    .detail {
      @extend %font-normal;
      color: #aaa;
      font-size: 14px;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 30px;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-bottom-color: #333;
      border-top: 0;
      margin-left: -5px;
      margin-top: -5px;
    }
  }
}
</style>
